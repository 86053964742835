
import Refiner from 'refiner-js';
import Config from '@config/index';
import { i18n } from '@translations/i18n';


export class FeedbackService {

    public static init() {
        Refiner('setProject', Config.REFINER.PROJECT_ID);
    }

    public static setUserInfo = (userInfo:any) => {
        Refiner('identifyUser',{
            id: userInfo.id,
            email: userInfo.email,
            name: userInfo.name,
            locale: i18n.language
        });
    }

    public static resetUser = () => {
        Refiner('resetUser');
    }
    
    public static trackEvent = (eventName:string) => {
        Refiner('trackEvent', eventName);
    }


    public static showWebSurvey = (surveyCode: string) => {
        Refiner('showForm', surveyCode);
    }
       
}