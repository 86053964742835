
import { clarity } from 'react-microsoft-clarity';
import Config from '@config/index';


export class MSClarityService {

    public static init() {
        clarity.init(Config.MS_CLARITY_CONFIG.TRACKING_CODE);
    }

    public static setUserId = (uuid: string) => {
        if (clarity.hasStarted()) {
            clarity.identify('USER_ID', { userProperty: uuid });
        }
    }

    public static setTag = (key:string, value:string) => {
        clarity.setTag(key, value);
    }

}
  