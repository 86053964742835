import {
    call,
    ForkEffect,
    put,
    takeLatest
} from 'redux-saga/effects';

import { CareerAdvisorRepository } from '@common/domain/repositories/CareerAdvisorRepository';
import {
    addOrRemoveFavouritesFailure,
    addOrRemoveFavouritesSuccess,
    deleteAttributeUserResponseFailure,
    deleteAttributeUserResponseSuccess,
    fetchAttributeGroupsFailure,
    fetchAttributeGroupsSuccess,
    fetchCareerPreferenceFailure,
    fetchCareerPreferenceSuccess,
    fetchJobDetailByIdSuccess,
    fetchJobRolesFailure,
    fetchJobRolesSuccess,
    getAttributesResponsesByUserIdFailure,
    getAttributesResponsesByUserIdSuccess,
    getFavoritedJobProfileByUserIdFailure,
    getFavoritedJobProfileByUserIdSuccess,
    getFavoriteJobrolesByUserIdFailure,
    getFavoriteJobrolesByUserIdSuccess,
    getFavouritesKeysFailure,
    getFavouritesKeysSuccess,
    getJobProfileByUserIdFailure,
    getJobProfileByUserIdSuccess,
    getJobProfilesFailure,
    getJobProfilesSuccess,
    getPassionJobProfileByUserIdFailure,
    getPassionJobProfileByUserIdSuccess,
    getPassionsByUserIdFailure,
    getPassionsByUserIdSuccess,
    getPassionsFailure,
    getPassionsSuccess,
    getPssciveCoursesFailure,
    getPssciveCoursesSuccess,
    getQuestionnaireResponseFailure,
    getQuestionnaireResponseSuccess,
    getUserDetailsByUserIdFailure,
    getUserDetailsByUserIdSuccess,
    getUserPreferencesByUserIdFailure,
    getUserPreferencesByUserIdSuccess,
    IJobRolesPayload,
    IPassionPayloadModel,
    IPssciveCoursePayload,
    submitAttributeUserResponseFailure,
    submitAttributeUserResponseSuccess,
    updateCareerPreferenceFailure,
    updateCareerPreferenceSuccess,
    updatePassionsFailure,
    updatePassionsSuccess
} from './actions';

import { JobProfileModel } from '@common/domain/models/JobProfileModel';
import { PssciveCourse } from '@common/domain/models/PssciveCourse';
import { fetchJobRoles } from "@common/redux/careerAdvisor/actions";
import { getAttributeFilterResponse } from '@presentation/CareerAdvisor/utils';
import { IFluxStandardAction } from '@store/webInterfaces';
import { careerAdvisorActions } from './actions';



export function* fetchJobRolesSaga(action: IFluxStandardAction<IJobRolesPayload>) {

    try {
        const { page } = action.payload
        const response: {
            jobProfiles: JobProfileModel[],
            total: number
        } = yield call(CareerAdvisorRepository.fetchBrowsedJobRoles, action.payload);
        yield put(fetchJobRolesSuccess(response.jobProfiles, response.total, page));
    } catch (error) {
        yield put(fetchJobRolesFailure(error));
    }
}

export function* fetchJobDetailSaga(action: any) {
    try {
        const { Id, passionKeys, userId } = action.payload;
        let isInPassion = false;
        let isInQuestionnare = false
        const response = userId ? yield call(CareerAdvisorRepository.fetchJobProfilePublic, Id) : yield call(CareerAdvisorRepository.fetchJobProfileById, Id);
        if (passionKeys?.length > 0) {
            const passionResponse = userId ? yield call(CareerAdvisorRepository.getJobProfileByUserId, { passionKeys: passionKeys, nonPaginated: true, userId: userId }) : yield call(CareerAdvisorRepository.getJobProfile, { passionKeys: passionKeys, nonPaginated: true });
            const questionnaireResponse = userId ? yield call(CareerAdvisorRepository.getJobProfileByUserId, { recommendation: true, userId: userId }) : yield call(CareerAdvisorRepository.fetchBrowsedJobRoles, { recommendation: true })
            isInPassion = passionResponse.some(job => job?.jobRoleKey === response?.jobRoleKey);
            isInQuestionnare = questionnaireResponse?.jobProfiles?.some(job => job?.jobRoleKey === response?.jobRoleKey);
        }
        const jobProfileData = { response: response, isInQuestionnare: isInQuestionnare, isInPassion: isInPassion }
        yield put(fetchJobDetailByIdSuccess(jobProfileData));
    } catch (error) {
        yield put(fetchJobRolesFailure(error));
    }
}

export function* addOrRemoveFavouritesSaga(action: IFluxStandardAction) {
    try {
        const response = yield call(CareerAdvisorRepository.addOrRemoveFavourites, action.payload);
        yield put(addOrRemoveFavouritesSuccess(response));
    } catch (error) {
        yield put(addOrRemoveFavouritesFailure(error));
    }
}

export function* getFavouriteKeysSaga() {
    try {
        const response = yield call(CareerAdvisorRepository.getFavouriteKeys);
        yield put(getFavouritesKeysSuccess(response?.jobRoleKeys));
    } catch (error) {
        yield put(getFavouritesKeysFailure(error));
    }
}

export function* updateCareerPreferenceSaga(action: any) {
    try {
        const response = yield call(CareerAdvisorRepository.updateCareerPreference, action.payload);
        yield put(updateCareerPreferenceSuccess(response));
    } catch (error) {
        yield put(updateCareerPreferenceFailure(error));
    }
}

export function* fetchCareerPreferenceSaga(action: any) {
    try {
        const response = yield call(CareerAdvisorRepository.fetchCareerPreference, action.payload);
        yield put(fetchCareerPreferenceSuccess(response));
    } catch (error) {
        yield put(fetchCareerPreferenceFailure(error));
    }
}

export function* fetchAttributeGroupsSaga() {
    try {
        const response = yield call(CareerAdvisorRepository.fetchAttributesGroup);
        const attributeGroupResponse = yield call(CareerAdvisorRepository.fetchAttributesGroupResponse);
        const attributeFilterData = getAttributeFilterResponse(attributeGroupResponse, response);
        yield put(fetchAttributeGroupsSuccess(attributeFilterData));
    } catch (error) {
        yield put(fetchAttributeGroupsFailure(error));
    }
}

export function* submitAttributeUserResponseSaga(action: any) {
    try {
        const response = yield call(CareerAdvisorRepository.submitAttributeUserResponse, action.payload);
        yield put(submitAttributeUserResponseSuccess(response));
    } catch (error) {
        yield put(submitAttributeUserResponseFailure(error));
    }
}

export function* deleteAttributeUserResponseSaga(action: IFluxStandardAction) {
    try {
        const response = yield call(CareerAdvisorRepository.deleteAttributeUserResponse, action.payload);
        yield put(deleteAttributeUserResponseSuccess(response));
    } catch (error) {
        yield put(deleteAttributeUserResponseFailure(error));
    }
}

export function* getQuestionnaireResponseSaga(action: IFluxStandardAction) {
    try {
        const response = yield call(CareerAdvisorRepository.getQuestionnaireResponse, action.payload);
        yield put(getQuestionnaireResponseSuccess(response));
    } catch (error) {
        yield put(getQuestionnaireResponseFailure(error));
    }
}

export function* getPssciveCoursesSaga(action: IFluxStandardAction<IPssciveCoursePayload>) {

    try {
        const { page } = action.payload
        const response: {
            PSSCIVECourses: PssciveCourse[],
            total: number
        } = yield call(CareerAdvisorRepository.getPssciveCourses, action.payload);
        const filterJobRoles = Array.from(new Set(response.PSSCIVECourses.map(eachCourse => eachCourse.getJobProfiles()).flat()));
        if (filterJobRoles.length) {
            yield call(fetchJobRolesSaga, fetchJobRoles({ jobRoleKeys: filterJobRoles, nonPaginated: true }));
        }
        yield put(getPssciveCoursesSuccess(response.PSSCIVECourses, response.total, page));
    } catch (error) {
        yield put(getPssciveCoursesFailure(error));
    }
}

export function* getJobProfileByUserIdSaga(action: IFluxStandardAction) {
    try {
        const response: {
            jobProfiles: JobProfileModel[],
            favourite: boolean
        } = yield call(CareerAdvisorRepository.getJobProfileByUserId, action.payload);
        yield put(getJobProfileByUserIdSuccess(response));
    } catch (error) {
        yield put(getJobProfileByUserIdFailure(error));
    }
}

export function* getFavoritedJobProfileByUserIdSaga(action: IFluxStandardAction) {
    try {
        const response: {
            jobProfiles: JobProfileModel[],
            favourite: boolean
        } = yield call(CareerAdvisorRepository.getFavoritedJobProfileByUserId, action.payload);
        yield put(getFavoritedJobProfileByUserIdSuccess(response));
    } catch (error) {
        yield put(getFavoritedJobProfileByUserIdFailure(error));
    }
}

export function* getAttributesResponsesByUserIdSaga(action: IFluxStandardAction) {
    try {
        const response = yield call(CareerAdvisorRepository.getAttributesResponsesByUserId, action.payload);
        yield put(getAttributesResponsesByUserIdSuccess(response));
    } catch (error) {
        yield put(getAttributesResponsesByUserIdFailure(error));
    }
}

export function* getUserPreferencesByUserIdSaga(action: IFluxStandardAction) {
    try {
        const response = yield call(CareerAdvisorRepository.getUserPreferencesByUserId, action.payload);
        yield put(getUserPreferencesByUserIdSuccess(response));
    } catch (error) {
        yield put(getUserPreferencesByUserIdFailure(error));
    }
}

export function* getFavoriteJobrolesByUserIdSaga(action: IFluxStandardAction) {
    try {
        const response = yield call(CareerAdvisorRepository.getFavoriteJobrolesByUserId, action.payload);
        yield put(getFavoriteJobrolesByUserIdSuccess(response));
    } catch (error) {
        yield put(getFavoriteJobrolesByUserIdFailure(error));
    }
}

export function* getUserDetailsByUserIdSaga(action: IFluxStandardAction) {
    try {
        const response = yield call(CareerAdvisorRepository.getUserDataByUserId, action.payload);
        yield put(getUserDetailsByUserIdSuccess(response));
    } catch (error) {
        yield put(getUserDetailsByUserIdFailure(error));
    }
}

export function* getPassionsSaga(action: IFluxStandardAction) {
    try {
        const response: IPassionPayloadModel[] = yield call(CareerAdvisorRepository.getUserPassion);
        yield put(getPassionsSuccess(response));
    } catch (error) {
        yield put(getPassionsFailure(error));
    }
}

export function* getPassionsByUserIdSaga(action: IFluxStandardAction) {
    try {
        const response: IPassionPayloadModel[] = yield call(CareerAdvisorRepository.getPassionByUserId, action.payload);
        yield put(getPassionsByUserIdSuccess(response));
    } catch (error) {
        yield put(getPassionsByUserIdFailure(error));
    }
}

export function* updatePassionsSaga(action: IFluxStandardAction) {
    try {
        const response = yield call(CareerAdvisorRepository.updateUserPassion, action.payload);
        yield put(updatePassionsSuccess(response));
    } catch (error) {
        yield put(updatePassionsFailure(error));
    }
}

export function* getJobProfileSaga(action) {
    try {
        const { passionKeys, ...others } = action.payload;
        const resultsByKey = {};
        const response = yield call(CareerAdvisorRepository.getJobProfile, { passionKeys: passionKeys, ...others });
        passionKeys?.forEach(passionKey => {
            if (!resultsByKey[passionKey]) resultsByKey[passionKey] = [];
            response?.forEach(job => {
                if (job?.passions?.findIndex(passion => passion?.passionKey === passionKey) > -1) {
                    resultsByKey[passionKey] = [...resultsByKey[passionKey], job]
                }
            })
        })
        yield put(getJobProfilesSuccess(resultsByKey));
    } catch (error) {
        yield put(getJobProfilesFailure(error));
    }
}

export function* getPassionJobProfileByUserIdSaga(action) {
    try {
        const { passionKeys, ...others } = action.payload;
        const resultsByKey = {};
        const response = yield call(CareerAdvisorRepository.getPassionJobProfileByUserId, { passionKeys: passionKeys, ...others });
        passionKeys?.forEach(passionKey => {
            if (!resultsByKey[passionKey]) resultsByKey[passionKey] = [];
            response?.forEach(job => {
                if (job?.passions?.findIndex(passion => passion?.passionKey === passionKey) > -1) {
                    resultsByKey[passionKey] = [...resultsByKey[passionKey], job]
                }
            })
        })
        yield put(getPassionJobProfileByUserIdSuccess(resultsByKey));
    } catch (error) {
        yield put(getPassionJobProfileByUserIdFailure(error));
    }
}



export function* watchCareerAdvisor(): Generator<ForkEffect> {
    yield takeLatest(careerAdvisorActions.FETCH_JOB_ROLES, fetchJobRolesSaga);
    yield takeLatest(careerAdvisorActions.FETCH_JOBROLE_BY_ID, fetchJobDetailSaga);
    yield takeLatest(careerAdvisorActions.ADD_OR_REMOVE_FAVOURITE, addOrRemoveFavouritesSaga);
    yield takeLatest(careerAdvisorActions.GET_FAVOURITE_KEYS, getFavouriteKeysSaga);
    yield takeLatest(careerAdvisorActions.UPDATE_CAREER_PREFERENCE, updateCareerPreferenceSaga);
    yield takeLatest(careerAdvisorActions.FETCH_CAREER_PREFERENCE, fetchCareerPreferenceSaga);
    yield takeLatest(careerAdvisorActions.FETCH_ATTRIBUTE_GROUPS, fetchAttributeGroupsSaga);
    yield takeLatest(careerAdvisorActions.SUBMIT_ATTRIBUTE_USER_RESPONSE, submitAttributeUserResponseSaga);
    yield takeLatest(careerAdvisorActions.DELETE_ATTRIBUTE_USER_RESPONSE, deleteAttributeUserResponseSaga);
    yield takeLatest(careerAdvisorActions.GET_QUESTIONNAIRE_RESPONSE, getQuestionnaireResponseSaga);
    yield takeLatest(careerAdvisorActions.GET_PSSCIVE_COURSES, getPssciveCoursesSaga);
    yield takeLatest(careerAdvisorActions.GET_JOB_PROFILE_BY_USERID, getJobProfileByUserIdSaga);
    yield takeLatest(careerAdvisorActions.GET_FAVORITED_JOB_PROFILE_BY_USERID, getFavoritedJobProfileByUserIdSaga);
    yield takeLatest(careerAdvisorActions.GET_ATTRIBUTES_RESPONSES_BY_USERID, getAttributesResponsesByUserIdSaga);
    yield takeLatest(careerAdvisorActions.GET_USER_PREFERENCES_BY_USERID, getUserPreferencesByUserIdSaga);
    yield takeLatest(careerAdvisorActions.GET_FAVORITE_JOBROLES_BY_USERID, getFavoriteJobrolesByUserIdSaga);
    yield takeLatest(careerAdvisorActions.GET_USER_DETAILS_BY_USERID, getUserDetailsByUserIdSaga);
    yield takeLatest(careerAdvisorActions.GET_PASSIONS, getPassionsSaga);
    yield takeLatest(careerAdvisorActions.UPDATE_PASSIONS, updatePassionsSaga);
    yield takeLatest(careerAdvisorActions.GET_JOBPROFILES, getJobProfileSaga);
    yield takeLatest(careerAdvisorActions.GET_PASSIONS_BY_USERID, getPassionsByUserIdSaga)
    yield takeLatest(careerAdvisorActions.GET_PASSIONS_JOBPROFILES_BY_USERID, getPassionJobProfileByUserIdSaga)
}